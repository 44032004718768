import { containEvent, debounce, pjaxFetch, fadeIn } from '../droom/utilities';

class ProcessionList {
  constructor(el) {
    this.el = el;
    this.slider = el.querySelector('.slider');
    this.rails = el.querySelector('.rails');
    this.next = this.el.querySelector('a.next');
    this.prev = this.el.querySelector('a.prev');
    this.close = this.el.querySelector('a.close');
    this.view_controls = this.el.querySelectorAll('a[data-view]');

    this.touchstartx = undefined;
    this.touchstarty = undefined;
    this.touchmovex = undefined;
    this.touchmovey = undefined;
    this.movex = undefined;
    this.index = 0;
    this.maxDrag = 0;
    this.longTouch = undefined;

    this.setAspect();
    const resizeObserver = new ResizeObserver(this.setAspect.bind(this));
    resizeObserver.observe(this.el);
  }

  init() {
    this.serials = this.el.querySelectorAll('.serial_listing');
    this.serials_with_images = this.el.querySelectorAll('.serial_listing.with_image');
    this.max = this.serials.length - 1;
    this.view_controls.forEach((a) => {
      a.addEventListener('click', (e) => {
        containEvent(e);
        this.setView(a.dataset.view);
      });
    });

    this.serials_with_images.forEach((el) => {
      el.addEventListener('click', (e) => {
        if (e.target.tagName !== 'A') {
          containEvent(e);
          this.showInGallery(el);
        }
      });
    });

    // if (this.max > 0 && this.slideWidth < 600) {
    //   // small screens default to gallery view
    //   this.showInGallery(this.serials[0], false);
    // }

    window.addEventListener("popstate", this.restoreState.bind(this));
    if (window.location.hash) {
      const initial_serial = window.location.hash.replace('#', '')
      const serial_el = document.getElementById(initial_serial);
      if (serial_el) {
        this.showInGallery(serial_el, false);
      }
    }
  }

  setView(view) {
    if (view === 'gallery') {
      this.galleryMode();
    } else {
      this.listMode();
    }
  }

  showInGallery(serial_el, save_state=true) {
    this.galleryMode();
    if (serial_el && serial_el.dataset.index) {
      this.panTo(parseInt(serial_el.dataset.index, 10), save_state);
    }
    // this.slider.classList.add('shimmy');
  }

  closeGallery() {
    this.listMode();
    this.saveState();
  }

  galleryMode() {
    this.slider.addEventListener('touchstart', this.touchStart.bind(this));
    this.slider.addEventListener('touchmove', this.touchMove.bind(this));
    this.slider.addEventListener('touchend', this.touchEnd.bind(this));
    this.next.addEventListener('click', this.moveNext.bind(this));
    this.prev.addEventListener('click', this.movePrev.bind(this));
    this.close.addEventListener('click', this.closeGallery.bind(this));
    this.slider.classList.add('up');
  }

  listMode() {
    this.slider.classList.remove('up');
    this.slider.removeEventListener('touchstart', this.touchStart.bind(this));
    this.slider.removeEventListener('touchmove', this.touchMove.bind(this));
    this.slider.removeEventListener('touchend', this.touchEnd.bind(this));
    this.next.removeEventListener('click', this.moveNext.bind(this));
    this.prev.removeEventListener('click', this.movePrev.bind(this));
    this.close.removeEventListener('click', this.listMode.bind(this));
  }

  slideAt(index) {
    return this.serials[index]
  }

  movePrev(e) {
    containEvent(e)
    if (this.index === 0) this.panTo(this.max)
    else this.panTo(this.index - 1);
  }

  moveNext(e) {
    containEvent(e);
    if (this.index === this.max) this.panTo(1)
    else this.panTo(this.index + 1);
  }

  panTo(index, save_state=true) {
    if (index >= 0 && index <= this.max) {
      this.rails.classList.add('animate')
      const offset = index * this.slideWidth;
      this.rails.style.transform = `translate3d(-${offset}px,0,0)`;
      this.index = index;
      const serial_el = this.slideAt(index);
      serial_el.scroll({top: 0, left: 0, behavior: "smooth"});
      // window.scroll({top: 0, left: 0, behavior: "smooth"});
      if (save_state) {
        this.saveState(serial_el);
      }
    }
  }

  revert() {
    this.panTo(this.index);
  }

  reset() {
    this.panTo(0);
  }

  // Touch event handlers
  //
  touchStart(event) {
    this.longTouch = false;
    const flickCheck = () => { this.longTouch = true; };
    setTimeout(flickCheck.bind(this), 250);
    this.touchstartx = event.touches[0].pageX;
    this.touchstarty = event.touches[0].pageY;
     // don't transition while dragging
    document.querySelectorAll('.animate').forEach((el) => {
      el.classList.remove('animate');
    });
  }

  touchMove(event) {
    this.touchmovex = event.touches[0].pageX;
    this.touchmovey = event.touches[0].pageY;
    const mostlyX = Math.abs(this.touchmovex - this.touchstartx) > Math.abs(this.touchmovey - this.touchstarty);
    this.movex = this.index * this.slideWidth + (this.touchstartx - this.touchmovex);
    if (!mostlyX) {
      this.movex = 0
    } else {
      if (this.movex < 0) {
        this.movex = -(2 * Math.sqrt(Math.abs(this.movex)));
      } else if (this.movex > this.maxDrag) {
        this.movex = this.maxDrag + (2 * Math.sqrt(Math.abs(this.movex - this.maxDrag)));
      }
      this.rails.style.transform = `translate3d(${-this.movex}px,0,0)`;
    }
  }

  touchEnd() {
    // Get size of swipe
    const absMove = Math.abs(this.movex);

    // Check for sufficient movement:
    // dragged more than halfway,      or a quick flick
    const significantMove = (absMove > (this.slideWidth / 3) || this.longTouch === false);

    // Establish direction if any
    let direction;
    if (this.movex > (this.index * this.slideWidth)) direction = 'right';
    else if (this.movex < (this.index * this.slideWidth)) direction = 'left';
    else direction = 'unmoved';

    // move to specified slide if there is one, or rebound to current slide
    if (significantMove && direction === 'right' && this.index < this.max) {
      this.moveNext();
    } else if (significantMove && direction === 'left' && this.index > 0) {
      this.movePrev();
    } else {
      this.revert();
    }
    this.touchstartx = undefined;
    this.touchmovex = undefined;
    this.movex = undefined;
  }

  setAspect() {
    const rect = this.slider.getBoundingClientRect();
    const aspect = rect.width / rect.height;
    this.slideWidth = rect.width;
    this.maxDrag = this.max * this.slideWidth;
  } 

  saveState(serial_el) {
    const url = new URL(window.location.href);
    let state;
    let title;
    if (serial_el) {
      const el_id = serial_el.id;
      url.hash = el_id;
      const serial = serial_el.dataset.serial;
      const title_el = serial_el.querySelector('h2');
      title = title_el && title_el.innerText;
      state = { serial }
    } else {
      url.hash = "";
      const title_el = document.head.querySelector('title');
      title = title_el && title_el.innerText;
      state = {}
    }
    history.pushState(state, title, url);
  }

  restoreState(e) {
    containEvent(e);
    if (e.state && e.state.serial) {
      const el = this.el.querySelector(`[data-serial="${e.state.serial}"]`);
      if (el) {
        this.showInGallery(el);
      }
    }
  }
}

function processionList(el) {
  const pl = new ProcessionList(el) ;
  pl.init()
  return pl;
}

export default processionList;