import 'lazysizes';
import slideshow from './tlms/slideshow';
import masthead from './tlms/masthead';
import searchForm from './tlms/search';
import processionList from './tlms/procession';
import fadeFlashes from './tlms/flashes';
import smartquotes from 'smartquotes';
import { balanceHeadings } from './tlms/text';
import { activate, addActivation, addListenerActivation, addSelectorActivation } from './droom/activate';
import { fetchToc, fetchButtons, fetchOnClick } from './tlms/fetches';
import { startLogging, log } from './droom/logging';
import { remoteLink, submitFormLink } from './droom/helpers';
import { fetchRegisterForm } from './droom/registration';
import { zoomOnClick } from './droom/zoomer';

/*
  Here we build a collection of activators that will be applied to each new element
  as it is added to the page, and to the whole page when it is first loaded.
  Other activators might be declared by other scripts; all will be applied in order of declaration.
*/

/* Content features */
addSelectorActivation('masthead', '#tlms_nav', masthead);
addSelectorActivation('search', '.search_holder', searchForm);
addSelectorActivation('slideshow', '.cms-slides', slideshow);
addSelectorActivation('procession', '#processional_order', processionList);
addListenerActivation('zoom', '[data-zoomed]', ' click', zoomOnClick);

/* Post-cache content fetches */

addSelectorActivation('toc', 'div.pages[data-content]', fetchToc);
addSelectorActivation('frag', '[data-fragment="takepart"]', fetchRegisterForm);
addSelectorActivation('page_button', '#buttons[data-page]', fetchButtons);
addSelectorActivation('page_collection_button', '#buttons[data-page-collection]', fetchButtons);
addSelectorActivation('replace_me', 'a.replace_me', fetchOnClick);
addSelectorActivation('remote_link', 'a[remote], a[data-remote], a[data-method]', remoteLink);
addSelectorActivation('submit_link', 'a[data-action="submit"]', submitFormLink);

/* Presentation niceties */

addActivation('smartquotes', smartquotes);
addActivation('balance', balanceHeadings);

/* On page load, apply all the activators to the whole document. */

document.addEventListener('DOMContentLoaded', (_) => {
  startLogging(); // unless production...
  log('✅ DOMContentLoaded');
  activate(document);
  fadeFlashes();
});
