import { containEvent } from "../droom/utilities"

// archive search
// and we only need to add a bit of show and hide.
//
class SearchForm {
  constructor(el) {
    this.wrapper = el;
    this.form = this.wrapper.querySelector('form');
    this.field = this.form.querySelector('input[type="text"]');
    this.original_value = this.field.value;
    this.shower = this.wrapper.querySelector('a.show');
    this.submitter = this.wrapper.querySelector('a.search');
    this.canceller = this.wrapper.querySelector('a.cancel');
  }

  init() {
    this.shower.addEventListener('click', this.show.bind(this));
    this.canceller.addEventListener('click', this.hide.bind(this));
    this.submitter.addEventListener('click', this.go.bind(this));
    this.field.addEventListener('input', this.setReadiness.bind(this));
  }

  go(e) {
    containEvent(e);
    this.form.submit();
  }

  show(e) {
    containEvent(e);
    this.wrapper.classList.add('up');
    this.field.focus();
  }

  hide(e) {
    containEvent(e);
    this.wrapper.classList.remove('up');
  }

  setReadiness() {
    const q = this.field.value;
    if (q && q.length > 2 && q !== this.original_value) {
      this.wrapper.classList.add('ready');
    } else {
      this.wrapper.classList.remove('ready');
    }
  }

}


function searchForm(el) {
  const sf = new SearchForm(el) ;
  sf.init()
  return sf;
}


export default searchForm;
