function fadeFlashes() {

  window.setTimeout(() => {
    document.querySelectorAll('#flashes p:not(:empty)').forEach((el) => {
      el.ontransitionend = () => el.remove();
      el.classList.add('disappearing');
    });
  }, 5000);
}


export default fadeFlashes;