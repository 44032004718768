import balanceText from 'balance-text';


function balanceHeadings() {
  balanceText('h1.pagetitle', {watch: true});
}


export {
  balanceHeadings
};