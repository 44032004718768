function fetchOrCatch(url, fn, opts={}) {
  const request = new Request(url, opts);
  request.headers.set('X-PJAX', 'true');
  fetch(request)
    .then((r) => {
      if (r.ok) {
        return r.text();
      } else {
        console.log('failed', r);
        throw new Error('Network response was not ok');
      }
    })
    .then((text) => {
      if (fn) fn(text);
    })
    .catch((error) => {
      console.error(error);
    });
}

function fetchAndReplace(el, url) {
  fetchOrCatch(url, (text) => {
    el.outerHTML = text;
  });
}

function fetchButtons(el) {
  const pageId = el.dataset.page;
  if (pageId) {
    return fetchAndReplace(el, `/parts/controls/${pageId}`);  
  }
  const pageCollectionSlug = el.dataset.collection;
  if (pageCollectionSlug) {
    return fetchAndReplace(el, `/parts/collection_controls/${pageCollectionSlug}`);  
  }
}

function fetchOnClick(el) {
  el.addEventListener('click', (e) => {
    const url = el.href;
    if (url) {
      e.preventDefault();
      fetchAndReplace(el, url);
    }
  });
}

function fetchToc(el) {
  const content = el.dataset.content;
  const listEl = el.querySelector('ul') || el;
  if (content === 'list') {
    const pageList = el.dataset.pages;
    if (pageList) {
      const pageIds = pageList.split(',').map((p) => {
        return parseInt(p, 10);
      }).join(',');
      if (pageIds) fetchAndReplace(listEl, `/toc/list/${pageIds}`);
    }
  } else if (content === 'siblings') {
    const pageId = el.dataset.page;
    if (pageId) fetchAndReplace(listEl, `/toc/siblings/${pageId}`);  
  } else if (content === 'children') {
    const pageId = el.dataset.page;
    if (pageId) fetchAndReplace(listEl, `/toc/children/${pageId}`);  
  } else if (content === 'similar') {
    const pageId = el.dataset.page;
    if (pageId) fetchAndReplace(listEl, `/toc/similar/${pageId}`);  
  }

  if (el.dataset.place) {
    const marker = document.querySelector('#cms_content p:nth-of-type(2)');
    if (marker) {
      marker.parentNode.insertBefore(el, marker.nextSibling);
    }
  }
}
export {
  fetchOrCatch,
  fetchButtons,
  fetchOnClick,
  fetchToc,
}