import {fetchOrCatch} from "../tlms/fetches";

class RegisterForm {
  constructor(el) {
    this.form = el;
    this.email_field = el.querySelector('input[type="email"]');
    if (this.email_field) {
      this.email_field.addEventListener('input', this.checkState.bind(this));
    }
    this.register_button = el.querySelector('button.register');
    if (this.register_button) {
      this.register_button.addEventListener('click', this.registerMe.bind(this));
    }
    this.notifier = el.querySelector('.messages');
    this.checkState();
  }

  checkState() {
    this.emailable = this.sane_email;
  }

  registerMe(e) {
    if (e) e.preventDefault();
    if (this.sane_email) {
      const email = this.email_field.value;
      const action = this.form.action;
      this.form.classList.add('busy');
      fetch(action, {
        method: "POST",
        body: JSON.stringify({ email: email }),
        headers: { 'Content-Type': 'application/json' },
      })
      .then((r) => {
        if (r.ok) {
          console.log('registration success', r);
          this.invitationSent();
        } else {
          console.log('registration failed', r);
          throw new Error(`Network response was not ok: ${r.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        this.invitationFailed();
      });
    }
  }

  invitationSent() {
    this.form.classList.remove('busy');
    this.form.classList.add('invitationSent');
  }

  invitationFailed() {
    this.form.classList.remove('busy');
    this.form.classList.add('error');
    if (this.notifier) {
      this.notifier.innerText = "Sorry, that didn't work."
    }
  }

  resetForm(e) {
    if (e) e.preventDefault();
    this.form.classList.remove('busy');
    this.form.classList.remove('invitationSent');
  }

  set emailable(value) {
    if (value) this.form.classList.add('emailable');
    else this.form.classList.remove('emailable');
  }

  get sane_email() {
    const eml = this.email_field && this.email_field.value;
    return eml && eml.length && /\S+@\S+\.\S+/.test(eml);
  }
}

function registerForm(el) {
  return new RegisterForm(el);
}

function fetchRegisterForm(el) {
  fetchOrCatch('/parts/takepart', (text) => {
    const wrapper = el.parentNode;
    el.outerHTML = text;
    const form = wrapper.querySelector('form.register');
    if (form) {
      new RegisterForm(form);
    } else {
      // nice error?
    }
  });
}



export {
  registerForm,
  fetchRegisterForm,
};