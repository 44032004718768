import { containEvent } from "../droom/utilities"

let timer;
let menu_el;

function dontHidePanel(e) {
  e.stopPropagation();
  if (e.target.matches("a.j")) {
    e.target.classList.add('clicked');
    return;
  }
  const ancestor = e.target.closest("a.j");
  if (ancestor) ancestor.classList.add('clicked');
}

function hidePanel(e) {
  containEvent(e);
  clearPanelTimer();
  clearDocumentListener();
  menu_el.classList.remove('up');
  menu_el.querySelectorAll("[data-nav]").forEach((a) => {
    a.classList.remove('up');
  });
}

function hidePanelSoon() {
  clearPanelTimer();
  setPanelTimer();
}

function showPanel(key) {
  clearPanelTimer();
  setDocumentListener();
  menu_el.classList.add('up');
  menu_el.querySelectorAll("[data-nav]").forEach((a) => {
    if (a.getAttribute("data-nav") == key) {
      a.classList.add('up');
      a.classList.remove('down');
    } else {
      a.classList.add('down');
      a.classList.remove('up');
    }
  });
}

function toggleMenu(e) {
  containEvent(e);
  if (menu_el.classList.contains('up')) {
    menu_el.classList.remove('up');
  } else {
    menu_el.classList.add('up');
  }
}

function observeHovers() {
  menu_el.querySelectorAll("[data-nav]").forEach((a) => {
    const key = a.getAttribute("data-nav");
    a.addEventListener("mouseenter", () => { showPanel(key) });
    a.addEventListener("mouseleave", hidePanelSoon);
  });
}

function observeTaps() {
  el.querySelectorAll("a[data-nav]").forEach((a) => {
    const key = a.getAttribute("data-nav");
    a.addEventListener("touchend", (e) => { 
      containEvent(e);
      showPanel(key);
    });
  });
}

function setFloat() {
  if (window.pageYOffset > 10) {
    menu_el.classList.add('floated');
  } else {
    menu_el.classList.remove('floated');
  }
}

function setPanelTimer() {
  timer = window.setTimeout(hidePanel, 1500);
}

function clearPanelTimer() {
  if (timer) window.clearTimeout(timer);
  timer = null;
}

function setDocumentListener() {
  document.addEventListener('click', hidePanel);
}

function clearDocumentListener() {
  document.removeEventListener('click', hidePanel);
}

function masthead(el) {
  menu_el = el
  // phone-scale: single menu bringer-upper
  menu_el.querySelectorAll("a.menutoggle").forEach((a) => {
    a.addEventListener('click', toggleMenu);
  });

  // larger screen: menu remains visible, links bring up section navigation
  // first hover event sets up mouse interface
  menu_el.addEventListener("mouseenter", observeHovers );
  // first touch event sets up touch interface
  menu_el.addEventListener("touchStart", observeTaps );
  // click or tap within the menu should not bubble up to close it
  menu_el.addEventListener('click', dontHidePanel);
  // add visual cue to floated menu upon scroll
  window.addEventListener("scroll", setFloat, { passive: true })
}


export default masthead;